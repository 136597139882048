import { render, staticRenderFns } from "./TrendMicroAgreement.vue?vue&type=template&id=67705f71&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-783d792af9/0/cache/vue-loader-npm-15.10.0-778eb54c35-1bf6c9ffea.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports