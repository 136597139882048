import Vue from 'vue';
import Login from '@/pages/login/Login.vue';
import SvgIcon from '@vivotek/lib-svg-icon';
import '@/i18n/index';

import store from '@/store';
import 'less/common.less';

Vue.config.productionTip = false;
Vue.use(SvgIcon);

new Vue({
  store,
  render: (h) => h(Login)
}).$mount('#app');
