<template>
  <div class="reset-password-panel">
    <div class="reset-password-content">
      <div class="title">
        <h1 class="semi-bold">
          {{ $t('Change password') }}
        </h1>
        <h6>
          {{ $t('Please create your own password') }}
        </h6>
      </div>
      <div class="panel-field">
        <h5 class="bold">
          {{ $t('User name') }}
        </h5>
        <h4 class="bold user-name">
          {{ user.userName }}
        </h4>
      </div>
      <div class="panel-field password">
        <h5 class="bold">
          {{ $t('Password') }}
        </h5>
        <AppInputFields
          :type="isPasswordVisible ? 'text' : 'password'"
          id="password"
          v-model="password"
          @keyup.native.enter="resetPassword"
        />
        <SvgIcon
          :iconClass="`general_${ isPasswordVisible ? 'hide' : 'show' }`"
          :title="isPasswordVisible ? $t('Hide password') : $t('Show password')"
          size="20px"
          outerSize="32px"
          @click="togglePasswordVisible"
        />
        <div class="password-validate">
          <span class="p">
            {{ $t('Your password must have :') }}
          </span>
          <div class="password-rule">
            <SvgIcon
              :iconClass="`general_${ isMinimunLengthPassed ? 'check' : 'dot'}`"
              size="20px"
            />
            <span class="p">
              {{ $t('At least 8 characters with no space') }}
            </span>
          </div>
          <div class="password-rule">
            <SvgIcon
              :iconClass="`general_${ isOneAlphabetContained ? 'check' : 'dot'}`"
              size="20px"
            />
            <span class="p">
              {{ $t('One alphabet character') }}
            </span>
          </div>
          <div class="password-rule">
            <SvgIcon
              :iconClass="`general_${ isOneNumberContained ? 'check' : 'dot'}`"
              size="20px"
            />
            <span class="p">
              {{ $t('One numeric character') }}
            </span>
          </div>
          <PasswordStrength
            :strength="passwordStrength"
          />
        </div>
      </div>
      <div class="panel-field password">
        <h5 class="bold">
          {{ $t('Confirm password') }}
        </h5>
        <AppInputFields
          :type="isConfirmPasswordVisible ? 'text' : 'password'"
          id="confirmPassword"
          v-model="confirmPassword"
          :hasError="!isPasswordMatched"
          :errorMessage="$t('Password does not match.')"
          @keyup.native.enter="resetPassword"
        />
        <SvgIcon
          :iconClass="`general_${ isConfirmPasswordVisible ? 'hide' : 'show' }`"
          :title="isPasswordVisible ? $t('Hide password') : $t('Show password')"
          size="20px"
          outerSize="32px"
          @click="toggleConfirmPasswordVisible"
        />
      </div>
      <div class="button-list">
        <button
          class="apply-button"
          :class="{ disabled: !isPasswordAcceptable || !isPasswordMatched }"
          @click="resetPassword"
        >
          {{ $t('Apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PasswordStrengthValidator from '@/utility/passwordStrengthValidator';
import AppInputFields from '@/components/ui/AppInputFields.vue';
import PasswordStrength from '@/components/PasswordStrength.vue';

export default {
  name: 'ResetPasswordPanel',
  components: {
    AppInputFields,
    PasswordStrength,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
    };
  },
  computed: {
    ...mapState(['user', 'system']),
    isMinimunLengthPassed() {
      return PasswordStrengthValidator.validateMinimunLength(this.password);
    },
    isOneAlphabetContained() {
      return PasswordStrengthValidator.validateAlphabetCharacter(this.password);
    },
    isOneNumberContained() {
      return PasswordStrengthValidator.validateNumericCharacter(this.password);
    },
    isPasswordAcceptable() {
      return this.isMinimunLengthPassed && this.isOneAlphabetContained && this.isOneNumberContained;
    },
    isPasswordMatched() {
      return this.password === this.confirmPassword;
    },
    passwordComplexityScore() {
      return PasswordStrengthValidator.getPasswordComplexityScore(this.password);
    },
    passwordSizeScore() {
      return PasswordStrengthValidator.getPasswordSizeScore(this.password);
    },
    passwordScore() {
      return this.passwordSizeScore + this.passwordComplexityScore;
    },
    passwordStrength() {
      if (!this.isPasswordAcceptable) {
        return '';
      }
      if (this.passwordScore < 20) {
        return 'weak';
      }
      if (this.passwordScore < 35) {
        return 'medium';
      }
      return 'strong';
    },
  },
  methods: {
    ...mapActions('user', ['setUserInfo']),
    ...mapActions('system', ['updateSystemInfo']),
    togglePasswordVisible() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    toggleConfirmPasswordVisible() {
      this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    },
    setAutoSetup() {
      return this.updateSystemInfo({ is_auto_setup: true });
    },
    resetPassword() {
      if (!this.isPasswordAcceptable || !this.isPasswordMatched) {
        return;
      }
      const { password } = this;
      Promise.all([this.setUserInfo({ userName: this.user.userName, payload: { password } }), this.setAutoSetup()])
        .then(() => {
          this.$emit('onResetPassword');
        });
    }
  }
};
</script>

<style lang="less" scoped>
.reset-password-panel {
  .reset-password-content {
    .title {
      color: #0066FF;
      h6 {
        margin-top: rem(10);
        color: #6E6E6E;
      }
    }
    .panel-field {
      > h5 {
        line-height: rem(42);
        color: #202020;
      }
      > h4.user-name {
        color: #ADADAD;
      }

      &.password {
        span {
          color: #6E6E6E;
        }
        .password-validate {
          margin-top: rem(15);
        }
        .password-rule {
          display: flex;
          align-items: center;
          .icon-container {
            pointer-events: none;
            &.general_dot {
              color: #6E6E6E;
            }
          }
        }
      }
    }
  }
}
</style>
