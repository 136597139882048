<template>
  <div
    class="password-strength"
    :data-strength="strength"
  >
    <div class="label">
      <span>{{ $t('Strength') }} </span>
      <span
        class="strength-label"
      >
        {{ strengthText }}
      </span>
    </div>
    <div class="strength-indicator">
      <div /><div /><div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordStrength',
  props: {
    strength: {
      type: String,
      default: '',
      validator: (value) => ['', 'weak', 'medium', 'strong'].indexOf(value) !== -1
    }
  },
  data() {
    return {
      strengthTextMap: {
        weak: this.$t('Weak'),
        medium: this.$t('Medium'),
        strong: this.$t('Strong'),
      }
    };
  },
  computed: {
    strengthText() {
      return this.strengthTextMap[this.strength] || '';
    }
  }
};
</script>

<style scoped lang="less">

@password-weak-color: #FF615C;
@password-medium-color: #FFB029;
@password-strong-color: #4FD345;

.password-strength {
  margin: rem(10) 0;
  .label {
    display: flex;
    justify-content: space-between;
    color: #6E6E6E;
  }
  .strength-indicator {
    background-color: #AEAEAE;
    height: rem(2);
    margin-top: rem(10);
    display: flex;
    > div {
      flex: 1;
    }
  }
  &[data-strength="weak"] {
    .strength-label {
      color: @password-weak-color;
    }
    .strength-indicator {
      > div:nth-child(1) {
        background-color: @password-weak-color;
      }
    }
  }
  &[data-strength="medium"] {
    .strength-label {
      color: @password-medium-color;
    }
    .strength-indicator {
      > div:nth-child(1), > div:nth-child(2) {
        background-color: @password-medium-color;
      }
    }
  }
  &[data-strength="strong"] {
    .strength-label {
      color: @password-strong-color;
    }
    .strength-indicator > div {
      background-color: @password-strong-color;
    }
  }
}
</style>
