const PASSWORD_MINIMUN_LENGTH = 8;

export default {
  validateMinimunLength(password) {
    return password.length >= PASSWORD_MINIMUN_LENGTH;
  },
  validateAlphabetCharacter(password) {
    return !!password.match(/[a-zA-Z]/g);
  },
  validateNumericCharacter(password) {
    return !!password.match(/\d/g);
  },
  getPasswordComplexityScore(password) {
    let score = 0;
    const upperCase = password.match(/[A-Z]/g);
    const lowerCase = password.match(/[a-z]/g);
    if (upperCase) {
      score += 5;
    }
    if (lowerCase) {
      score += 1;
    }
    if (upperCase && lowerCase) {
      score += 2;
    }

    const digits = password.match(/\d/g);
    if (digits && digits.length > 1) {
      score += 5;
    }

    const nonAlpha = password.match(/\W/g);
    if (nonAlpha) {
      score += (nonAlpha.length > 1) ? 15 : 10;
    }

    if (upperCase && lowerCase && digits && nonAlpha) {
      score += 15;
    }

    if (password.match(/\s/)) {
      score += 10;
    }
    return score;
  },
  getPasswordSizeScore(password) {
    let score = 0;
    const { length } = password;

    if (length < 5) {
      score += 0;
    } else if (length < 8) {
      score += 5;
    } else if (length < 16) {
      score += 10;
    } else {
      score += 15;
    }
    return score;
  }
};
